<template>
  <div class="row mb-8">
    <div class="col-4">
      <kpi-card-v2 :title="'N/A'" :text="'7 days'" />
    </div>
    <div class="col-4">
      <kpi-card-v2 :title="'N/A'" :text="'1 month'" />
    </div>
    <div class="col-4">
      <kpi-card-v2
        :title="
          totalStreams ? formatNumberIntoHumanizeForm(totalStreams) : 'N/A'
        "
        :text="'All Time'"
      />
    </div>
  </div>
  <EmptyCardLoading
    :loading="loading"
    :type="'skeleton'"
    v-if="loading"
    :card-height="'450px'"
    :rows="10"
    class="mb-5 mb-xl-10"
  />
  <CardToolbar
    :show-toolbar-buttons="false"
    description=""
    :title="cardTitle"
    :fontStyle="true"
    :margin="true"
    :padding="false"
    :shadow-class="true"
    v-if="series.length > 0 && categories.length > 0 && !loading"
  >
    <template v-slot:body>
      <ScoreChartV2
        :colors="colors"
        :stroke="stroke"
        :chart-series="series"
        :categories="categories"
        :show-label="true"
        :graph-type="'area'"
        :fill="fill"
        :show-yaxis-title="yAxisTitle"
        :tickAmount="30"
      />
    </template>
  </CardToolbar>
</template>
<script>
import { onMounted, ref } from "vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import KpiCardV2 from "@/common/components/KpiCardV2.vue";
import ScoreChartV2 from "@/artists/components/ScoreChartV2.vue";
import { getTrackStatsWeeklyDiffBySpotifyID } from "@/api/mongoTracks";
import moment from "moment/moment";
import createTrend from "trendline";
import { useRoute } from "vue-router";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import { NumberOfYearsEnum } from "@/common/enums/numberOfYearsEnum";
import { MongoSortingOrderEnum } from "@/common/enums/mongoSortingOrderEnum";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "StreamsChartV2",
  components: { CardToolbar, EmptyCardLoading, KpiCardV2, ScoreChartV2 },
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    platform: {
      type: String,
      required: true,
    },
    yAxisTitle: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const stroke = ref({
      width: [3, 2],
      dashArray: [0, 8],
      curve: "smooth",
    });
    const fill = ref({
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.55,
      },
    });
    const loading = ref(false);
    const series = ref([]);
    const categories = ref([]);
    const totalStreams = ref(0);
    const route = useRoute();
    onMounted(async () => {
      if (route.params.spotifyID && route.params.trackID) {
        await getSpotifyTrackStreamsData();
      }
    });
    const getSpotifyTrackStreamsData = async () => {
      try {
        loading.value = true;
        let { data } = await getTrackStatsWeeklyDiffBySpotifyID(
          route.params.spotifyID,
          route.params.trackID,
          NumberOfYearsEnum.ONEYEAR - 1,
          MongoSortingOrderEnum.DESCENDING,
          props.platform
        );
        const trend = createTrend(
          data ? data.multiYearsTrackStatssOfEveryMonthSingleData : null,
          "timestp",
          "value"
        );
        const trendline = [];
        const streams = [];
        if (data && data.multiYearsTrackStatssOfEveryMonthSingleData.length) {
          for (const stream of data.multiYearsTrackStatssOfEveryMonthSingleData) {
            streams.push(stream.value);
            categories.value.push(
              moment(stream.timestp * 1000).format("MMM DD, YY")
            );
            trendline.push(Math.round(trend.calcY(stream.timestp)));
          }
        }
        totalStreams.value = data ? data.allTimeTotalStreams : 0;
        series.value.push({
          name: props.yAxisTitle,
          type: "line",
          data: streams,
        });
        series.value.push({
          name: "Trend Line",
          type: "area",
          data: trendline,
        });
        data = [];
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };
    return {
      loading,
      series,
      categories,
      stroke,
      fill,
      formatNumberIntoHumanizeForm,
      totalStreams,
    };
  },
};
</script>
