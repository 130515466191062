<template>
  <apexchart
    ref="chartRef"
    :series="series"
    :options="chartOptions"
  ></apexchart>
</template>
<script>
import { ref } from "vue";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "SparklineChart",
  props: {
    chartSeries: {
      type: Array,
      default: () => [],
    },
    chartCategories: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => ["rgb(0, 179, 0)"],
    },
  },
  setup(props) {
    const series = ref(props.chartSeries);
    const chartOptions = ref({
      chart: {
        type: "area",
        height: 160,
        sparkline: {
          enabled: true,
        },
      },

      colors: props.colors,
      stroke: {
        width: [3, 2],
        dashArray: [0, 8],
        curve: "smooth",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.55,
        },
      },
      xaxis: {
        categories: props.chartCategories,
        crosshairs: {
          width: 1,
        },
      },
      yaxis: {},
      tooltip: {
        y: [
          {
            formatter: function (value) {
              return formatNumberIntoHumanizeForm(value);
            },
          },
          {
            formatter: function (value) {
              return formatNumberIntoHumanizeForm(value);
            },
          },
        ],
      },
    });
    return { series, chartOptions };
  },
};
</script>
