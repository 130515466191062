<template>
  <div class="card shadow-card" :style="'height:' + cardHeight">
    <div class="card-body pb-1" v-if="type === 'skeleton'">
      <el-skeleton v-if="loader" :rows="rows" animated />
    </div>
    <div v-else class="card-body pb-1" v-loading="loader"></div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
export default {
  name: "EmptyCardLoading.vue",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cardHeight: {
      default: "200px",
    },
    type: {
      type: String,
      default: "spinner",
    },
    rows: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const loader = ref(false);
    watch(
      () => props.loading,
      (value) => {
        loader.value = value;
      },
      { immediate: true }
    );
    return { loader };
  },
};
</script>
