<template>
  <div id="key_track_stats" class="mt-2">
    <div class="card shadow-card mb-8">
      <div class="card-body p-6">
        <el-row class="platform-columns">
          <el-col
            :xs="12"
            :sm="6"
            :md="4"
            v-for="(platform, i) in platforms"
            :key="i"
            style="padding: 8px"
          >
            <el-card class="platform-fanbase" shadow="hover">
              <div
                :class="[
                  'd-flex flex-column align-items-center justify-content-between',
                  platform.dataFound ? 'active' : 'inActive',
                ]"
              >
                <div class="platform d-flex align-items-center">
                  <el-image
                    :class="
                      platform.platform === 'siriusxm' ? 'icon-2' : 'icon'
                    "
                    :src="
                      platform.dataFound
                        ? '/media/svg/social-logos/' +
                          platform.platform.replace(/\s+/g, '-') +
                          '.svg'
                        : '/media/svg/social-logos/' +
                          platform.platform.replace(/\s+/g, '-') +
                          '-disable.svg'
                    "
                  />
                  <span class="platform-name mt-md-1 fw-bold">
                    {{ platform.platform.toUpperCase() }}</span
                  >
                </div>
                <div class="text-center d-flex flex-column mt-5 w-100">
                  <span
                    class="followers-count fw-bold truncate w-100"
                    data-tag="span"
                    title=""
                    data-tooltip="true"
                    :data-text="formatNumberIntoHumanizeForm(platform.value)"
                    >{{
                      platform.value
                        ? formatNumberIntoHumanizeForm(platform.value)
                        : "N/A"
                    }}</span
                  >
                  <span class="footer-text">{{ platform.keyName }}</span>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>

  <div class="artist-content-heading">{{ cardHeading }} Track Stats</div>
  <div id="monthly_track_stats" class="mt-2">
    <EmptyCardLoading
      :loading="loading"
      :type="'skeleton'"
      v-if="loading"
      :card-height="'450px'"
      :rows="10"
      class="mb-5 mb-xl-10"
    />
    <CardToolbar
      :show-toolbar-buttons="true"
      description=""
      title=""
      :fontStyle="true"
      :margin="true"
      :padding="false"
      :shadow-class="true"
      v-if="!loading"
    >
      <template v-slot:toolbar>
        <button
          @click="changeYearValue(1)"
          class="btn btn-sm btn-color-muted btn-active btn-active-primary"
          :class="[{ active: years === 1 }]"
        >
          One Year
        </button>
        <button
          @click="changeYearValue(2)"
          class="btn btn-sm btn-color-muted btn-active btn-active-primary"
          :class="[{ active: years === 2 }]"
        >
          Two Years
        </button>
        <button
          @click="changeYearValue(3)"
          class="btn btn-sm btn-color-muted btn-active btn-active-primary"
          :class="[{ active: years === 3 }]"
        >
          Three Years
        </button>
      </template>
      <template v-slot:body>
        <el-row>
          <el-col
            :xs="12"
            :sm="6"
            :md="4"
            :lg="4"
            style="padding: 8px"
            v-for="(platform, index) in filterPlatforms"
            :key="index"
          >
            <div class="d-flex justify-content-between flex-column">
              <div class="d-flex justify-content-between align-items-center">
                <el-image
                  class="icon"
                  :src="'/media/svg/social-logos/' + platform.platform + '.svg'"
                />
                <div class="d-flex flex-column align-items-end">
                  <span class="fw-boldest" style="font-size: 18px">
                    {{ platform.totalCount }}
                  </span>
                  <span class="graph-key-text fw-bolder">{{
                    platform.key
                  }}</span>
                </div>
              </div>
              <div class="d-flex mt-6">
                <SparklineChart
                  :chart-series="platform.series"
                  :chart-categories="platform.categories"
                  :colors="[platform.color]"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
    </CardToolbar>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import SparklineChart from "@/common/components/SparklineChart.vue";
import CardToolbar from "@/common/components/CardToolbar.vue";
import EmptyCardLoading from "@/common/components/EmptyCardLoading.vue";
import {
  capitalizeEveryWordFirstLetter,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";
import { getTracksSpotifyStreams } from "@/api/mongoTracks";
import moment from "moment/moment";
import createTrend from "trendline";
import { useRoute } from "vue-router";
import { MongoSortingOrderEnum } from "@/common/enums/mongoSortingOrderEnum";

export default {
  name: "AllTimeTrackStatsV2",
  components: { CardToolbar, SparklineChart, EmptyCardLoading },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const loading = ref(false);
    const route = useRoute();
    const platforms = ref([
      {
        platform: "spotify",
        value: null,
        key: "spotify_plays",
        keyName: "Total Plays",
        dataFound: false,
      },
      {
        platform: "spotify",
        value: null,
        key: "spotify_playlist_count",
        keyName: "Playlist Counts",
        dataFound: false,
      },
      // {
      //   platform: "spotify",
      //   value: null,
      //   key: "sp_playlist_total_reach",
      //   keyName: "Playlist Reach",
      //   dataFound: false,
      // },
      // {
      //   platform: "spotify",
      //   value: null,
      //   key: "num_sp_editorial_playlists",
      //   keyName: "Editorial Playlist Reach",
      //   dataFound: false,
      // },
      {
        platform: "spotify",
        value: null,
        key: "spotify_popularity",
        keyName: "Popularity",
        dataFound: false,
      },
      // {
      //   platform: "youtube",
      //   value: null,
      //   key: "yt_playlist_total_reach",
      //   keyName: "Playlists Reach",
      //   dataFound: false,
      // },
      // {
      //   platform: "youtube",
      //   value: null,
      //   key: "num_tt_videos",
      //   keyName: "Videos",
      //   dataFound: false,
      // },
      // {
      //   platform: "youtube",
      //   value: null,
      //   key: "num_yt_playlists",
      //   keyName: "Playlists",
      //   dataFound: false,
      // },
      // {
      //   platform: "youtube",
      //   value: null,
      //   key: "num_yt_editorial_playlists",
      //   keyName: "Editorial Playlists",
      //   dataFound: false,
      // },
      {
        platform: "youtube",
        value: null,
        key: "youtube_likes",
        keyName: "Likes",
        dataFound: false,
      },
      {
        platform: "youtube",
        value: null,
        key: "youtube_playlist_count",
        keyName: "Playlist Counts",
        dataFound: false,
      },

      {
        platform: "soundcloud",
        value: null,
        key: "soundcloud_plays",
        keyName: "Total Plays",
        dataFound: false,
      },
      {
        platform: "tiktok",
        value: null,
        key: "tiktok_top_videos_likes",
        keyName: "Videos Likes",
        dataFound: false,
      },
      {
        platform: "tiktok",
        value: null,
        key: "tiktok_top_videos_views",
        keyName: "Videos Views",
        dataFound: false,
      },
      {
        platform: "tiktok",
        value: null,
        key: "tiktok_posts",
        keyName: "Posts",
        dataFound: false,
      },
      // {
      //   platform: "tiktok",
      //   value: null,
      //   key: "num_tt_videos",
      //   keyName: "Videos",
      //   dataFound: false,
      // },
      // {
      //   platform: "deezer",
      //   value: null,
      //   key: "de_playlist_total_reach",
      //   keyName: "Reach",
      //   dataFound: false,
      // },

      {
        platform: "deezer",
        value: null,
        key: "deezer_playlist_count",
        keyName: "Playlist Counts",
        dataFound: false,
      },
      // {
      //   platform: "deezer",
      //   value: null,
      //   key: "num_de_editorial_playlists",
      //   keyName: "Editorial Playlists",
      //   dataFound: false,
      // },
      // {
      //   platform: "apple music",
      //   value: null,
      //   key: "num_am_playlists",
      //   keyName: "Music Playlists",
      //   dataFound: false,
      // },
      // {
      //   platform: "apple music",
      //   value: null,
      //   key: "num_am_editorial_playlists",
      //   keyName: "Music Editorial Playlists",
      //   dataFound: false,
      // },
      {
        platform: "amazon music",
        value: null,
        key: "amazon_playlist_count",
        keyName: "Playlist Counts",
        dataFound: false,
      },
      // {
      //   platform: "amazon music",
      //   value: null,
      //   key: "num_az_editorial_playlists",
      //   keyName: "Music Editorial Playlists",
      //   dataFound: false,
      // },
      {
        platform: "shazam",
        value: null,
        key: "shazam_count",
        keyName: "Counts",
        dataFound: false,
      },
      {
        platform: "airplay",
        value: null,
        key: "airplay_streams",
        keyName: "Streams",
        dataFound: false,
      },
      {
        platform: "itunes",
        value: null,
        key: "itunes_playlist_count",
        keyName: "Playlist Counts",
        dataFound: false,
      },
      {
        platform: "siriusxm",
        value: null,
        key: "siriusxm_streams",
        keyName: "Streams",
        dataFound: false,
      },
    ]);
    const graphPlatforms = ref([
      {
        id: 1,
        platform: "spotify",
        series: [],
        categories: [],
        key: "Streams",
        color: "#48B955",
        totalCount: null,
        dataFound: false,
      },
      {
        id: 2,
        platform: "soundcloud",
        series: [],
        categories: [],
        color: "#FB663B",
        key: "Plays",
        totalCount: null,
        dataFound: false,
      },
      {
        id: 3,
        platform: "youtube",
        series: [],
        categories: [],
        color: "#FA423B",
        key: "Views",
        totalCount: null,
        dataFound: false,
      },
      {
        id: 4,
        platform: "tiktok",
        series: [],
        categories: [],
        color: "#000000",
        key: "Followers",
        totalCount: null,
        dataFound: false,
      },
      {
        id: 5,
        platform: "shazam",
        color: "#2979ff",
        series: [],
        categories: [],
        key: "Shazam",
        totalCount: null,
        dataFound: false,
      },
    ]);
    const years = ref(1);
    const cardHeading = ref("1-Years");
    onMounted(async () => {
      if (route.params.spotifyID && route.params.trackID) {
        await getPlatformsGraphData();
      }
    });
    const filterPlatforms = computed(() => {
      return graphPlatforms.value.filter((platform) => {
        return platform.dataFound === true;
      });
    });
    const getPlatformsValues = (obj) => {
      for (const platform of platforms.value) {
        if (platform.key in obj) {
          platform.value = obj[platform.key];
          if (platform.value) {
            platform.dataFound = true;
          }
        }
      }
    };
    const getPlatformsGraphData = async () => {
      try {
        loading.value = true;
        let promises = [];
        Object.values(graphPlatforms.value).forEach((platform) => {
          promises.push(
            getTracksSpotifyStreams(
              route.params.spotifyID,
              route.params.trackID,
              years.value,
              MongoSortingOrderEnum.DESCENDING,
              platform.platform
            ).then(({ data }) => {
              if (data.length > 0) {
                platform.categories = [];
                platform.series = [];
                const spotifyStreams = [];
                platform.dataFound = true;
                const trend = createTrend(data, "timestp", "value");
                const trendline = [];
                for (const stream of data) {
                  spotifyStreams.push(stream.value);
                  platform.categories.push(
                    moment(stream.timestp * 1000).format("MMM YY")
                  );
                  trendline.push(Math.round(trend.calcY(stream.timestp)));
                }
                if (spotifyStreams.length) {
                  platform.totalCount = formatNumberIntoHumanizeForm(
                    spotifyStreams[spotifyStreams.length - 1]
                  );
                }

                platform.series.push({
                  name: capitalizeEveryWordFirstLetter(platform.key),
                  type: "line",
                  data: spotifyStreams,
                });
                platform.series.push({
                  name: "Trend Line",
                  type: "area",
                  data: trendline,
                });
                data = [];
              } else {
                platform.dataFound = false;
              }
            })
          );
        });
        await Promise.all(promises);
        graphPlatforms.value.sort((a, b) => a.id - b.id);
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };
    const changeYearValue = (value) => {
      years.value = value;
      if (years.value === 1) {
        cardHeading.value = "1-year";
      } else {
        cardHeading.value = value + "-years";
      }
      getPlatformsGraphData();
    };
    watch(
      () => props.stats,
      (value) => {
        if (Object.keys(value).length) {
          getPlatformsValues(value);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      loading,
      platforms,
      years,
      cardHeading,
      graphPlatforms,
      filterPlatforms,
      changeYearValue,
      formatNumberIntoHumanizeForm,
    };
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 20px;
  height: 20px;
}

.icon-2 {
  width: 50px;
  height: 20px;
}

.platform-fanbase {
  //border-radius: 12px !important;

  .active {
    .platform {
      .platform-name {
        color: #9fa2aa;
        font-size: 0.8rem;
        margin-left: 0.5em;
      }
    }

    .footer-text {
      color: #9fa2aa;
      font-size: 0.7rem;
    }

    .followers-count {
      font-size: 1.3rem;
    }
  }

  .inActive {
    .platform {
      .platform-name {
        color: #d5dadd;
        font-size: 0.8rem;
        margin-left: 0.5em;
      }
    }

    .footer-text {
      color: #d5dadd;
      font-size: 0.7rem;
    }

    .followers-count {
      font-size: 1.3rem;
      color: #d5dadd;
    }
  }
}

.graph-key-text {
  color: #9fa2aa;
}
</style>
