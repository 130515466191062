<template>
  <apexchart
    :height="graphHeight"
    :type="graphType"
    :options="options"
    :series="series"
  ></apexchart>
</template>
<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import {
  capitalizeFirstLetter,
  formatNumberIntoHumanizeForm,
} from "@/utils/staticHelper";

export default {
  name: "ScoreChartV2",
  props: {
    chartSeries: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    stroke: {
      type: Object,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showXaxisTitle: {
      type: String,
      default: "",
    },
    showYaxisTitle: {
      type: String,
      default: "",
    },
    graphType: {
      type: String,
      default: "line",
    },
    fill: {
      type: Object,
      default: () => {
        return {
          opacity: 1,
        };
      },
    },
    tickAmount: {
      type: Number,
      default: 24,
    },
    colors: {
      type: Array,
      default: () => {
        return ["rgb(0, 161, 249)", "rgb(0, 179, 0)"];
      },
    },
    graphHeight: {
      type: Number,
      default: 350,
    },
    yAxisOpposite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const fontSize = ref("12px");
    const options = ref({
      chart: {
        height: props.graphHeight,
        type: props.graphType,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      fill: props.fill,
      colors: props.colors,
      legend: {
        show: props.showLabel,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: props.stroke,
      xaxis: {
        categories: props.categories,
        tickAmount: props.tickAmount,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: props.showXaxisTitle,
          style: {
            color: labelColor,
            fontSize: fontSize.value,
            fontWeight: 500,
          },
        },
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
        },
      },
      yaxis: props.yAxisOpposite
        ? [
            {
              seriesName: capitalizeFirstLetter(props.chartSeries[0].indicator),
              max: Math.max(...props.chartSeries[0].data),
              opposite: false,
              forceNiceScale: true,
              show: true,
              title: {
                text: capitalizeFirstLetter(props.chartSeries[0].name),
                style: {
                  color: labelColor,
                  fontSize: "12px",
                  fontWeight: 400,
                },
              },
              labels: {
                formatter: (value) => {
                  return formatNumberIntoHumanizeForm(value);
                },
                style: {
                  colors: labelColor,
                  fontSize: "12px",
                },
              },
            },
            {
              forceNiceScale: true,
              show: true,
              max: Math.max(...props.chartSeries[1].data),
              opposite: true,
              title: {
                text: capitalizeFirstLetter(props.chartSeries[1].name),
                style: {
                  color: labelColor,
                  fontSize: "12px",
                  fontWeight: 400,
                },
              },
              labels: {
                formatter: (value) => {
                  return formatNumberIntoHumanizeForm(value);
                },
                style: {
                  colors: labelColor,
                  fontSize: "12px",
                },
              },
            },
          ]
        : {
            title: {
              text: props.showYaxisTitle,
              style: {
                color: labelColor,
                fontSize: fontSize.value,
                fontWeight: 500,
              },
            },
            labels: {
              style: {
                colors: labelColor,
                fontSize: fontSize.value,
              },
              formatter: (value) => {
                return formatNumberIntoHumanizeForm(value);
              },
            },
          },

      tooltip: {
        style: {
          fontSize: fontSize.value,
        },
        y: [
          {
            formatter: function (value) {
              return formatNumberIntoHumanizeForm(value);
            },
          },
          {
            formatter: function (value) {
              return formatNumberIntoHumanizeForm(value);
            },
          },
        ],
      },
    });
    const series = ref(props.chartSeries);
    return { options, series };
  },
};
</script>
