<template>
  <div class="shadow-card card p-6 mb-8 mt-2" id="song_bio">
    <div class="d-flex">
      <div
        class="artist-image d-flex justify-content-between align-items-center"
      >
        <el-image
          class="song-cover"
          :src="
            songDetail && songDetail.image_url
              ? songDetail.image_url
              : '/media/avatars/blank.png'
          "
          :fit="'fill'"
        />
      </div>
      <article class="card-article d-flex flex-column">
        <div class="article-title">
          <h1 class="theme-v2-color">
            {{ songDetail && songDetail.name }}
          </h1>
          <div class="actions d-flex align-items-center">
            <div class="el-image" style="width: 30px; height: 30px">
              <img
                class="el-image__inner"
                src="/media/svg/song/play-button.svg"
                alt="play-button"
              />
            </div>
            <div class="el-image ms-1" style="width: 35px; height: 35px">
              <img
                class="el-image__inner"
                src="/media/svg/song/plus-button.svg"
                alt="plus-button"
              />
            </div>
          </div>
        </div>
        <div class="artist fw-bold">
          <span>{{
            songDetail && songDetail.artist && songDetail.artist.length > 0
              ? songDetail.artist.map((object) => object.name).join(", ")
              : "N/A"
          }}</span>
        </div>
        <div class="release-info mt-2">
          <article class="release-info-article" style="margin-left: 0">
            <time style="margin-left: 0"
              >Released on
              {{
                track && track.timestp ? getReleaseDate(track.timestp) : "N/A"
              }}
            </time>
          </article>
        </div>
        <div class="article-bottom fw-bold">
          <div class="tags">
            <span class="tag">
              {{
                songDetail && songDetail.genre
                  ? songDetail.genre.join(", ")
                  : "N/A"
              }}
            </span>
          </div>
          <div
            class="tags cursor-pointer"
            @click="
              routeToGenrePage(
                songDetail && songDetail.genre ? songDetail.genre : null
              )
            "
          >
            <div class="tag d-flex flex-column align-items-center">
              <div class="top">
                <span style="color: #0052cc">#5,453</span>
                <span class="rank-title ms-2">
                  {{
                    songDetail && songDetail.genre
                      ? songDetail.genre.join(", ")
                      : "N/A"
                  }}
                </span>
              </div>
              <div class="bottom">current position</div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { ref } from "vue";

export default {
  name: "SongOverviewV2",
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const songDetail = ref(
      props.track && props.track.chartmetricArtistTrackRelease
        ? props.track.chartmetricArtistTrackRelease
        : {}
    );
    const getReleaseDate = (date) => {
      return moment(date).format("MMM DD, YYYY");
    };
    const routeToGenrePage = (tag) => {
      if (tag) {
        window.open(`/genre-v2?type=main&genre=${tag.split(",")[0]}`, "_blank");
      }
    };
    return { songDetail, getReleaseDate, routeToGenrePage };
  },
};
</script>
<style scoped lang="scss">
.song-cover {
  width: 230px;
  height: 230px;
  min-width: 230px;
}

.card-article {
  margin-left: 16px;
  flex: 1 1;

  .article-title {
    padding-right: 200px;
    display: flex;

    h1 {
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 30px;
      line-height: 34px;
    }

    .actions {
      height: 34px;
      margin-left: 16px;
      white-space: nowrap;
    }
  }

  .artist {
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 1.5rem;
    line-height: 24px;
    margin-top: 8px;
  }

  .release-info {
    font-size: 1.1rem;
    color: gray;
  }

  .article-bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .tags {
    display: flex;
    margin: -4px;
    padding-top: 16px;

    .tag {
      margin: 4px;
      border-radius: 12px;
      background-color: #e4e6ef;
      color: black;
      padding: 4px 8px;
    }

    .bottom {
      color: #707580;
      margin-top: 2px;
    }
  }
}

.tag:hover {
  transform: scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
</style>
