<template>
  <div
    :class="[
      'shadow-card card artist-performance-card',
      backgroundColor ? 'card-background' : '',
      showHover ? 'hover-card' : '',
    ]"
    v-if="card"
  >
    <div
      :class="[
        'card-body',
        padding ? 'pt-8 pb-9 text-center theme-v2-color' : 'kpi-card-body',
      ]"
    >
      <div v-if="brandName">
        <el-image
          style="vertical-align: middle"
          class="icon"
          :src="'/media/svg/social-logos/' + brandName + '.svg'"
        />
        <span
          :class="['ms-4 truncate', pdf ? 'pdf-title' : 'kpi-card-title']"
          data-tag="span"
          title=""
          data-tooltip="true"
          :data-text="title"
          style="vertical-align: middle"
          >{{ title }}</span
        >
      </div>
      <p
        :class="['truncate mb-1', pdf ? 'pdf-title' : 'kpi-card-title']"
        data-tag="p"
        title=""
        data-tooltip="true"
        :data-text="title"
        v-else
      >
        {{ title }}
      </p>
      <p
        :class="[
          ' mb-0',
          tooltipText ? 'truncate' : '',
          pdf ? 'pdf-text' : 'kpi-card-text',
        ]"
        data-tag="p"
        title=""
        :data-tooltip="tooltipText"
        :data-text="text"
      >
        {{ text }}
      </p>
      <p
        :class="[' truncate mb-0', pdf ? 'pdf-sub-text' : 'kpi-card-sub-text']"
        data-tag="p"
        title=""
        data-tooltip="true"
        :data-text="subText"
      >
        {{ subText }}
      </p>
      <slot name="body"></slot>
    </div>
  </div>
  <div v-else-if="!card">
    <p
      :class="['truncate mb-1', pdf ? 'pdf-title' : 'kpi-card-title']"
      data-tag="p"
      title=""
      data-tooltip="true"
      :data-text="title"
    >
      {{ title }}
    </p>
    <p
      :class="[
        'mb-0',
        tooltipText ? 'truncate' : '',
        pdf ? 'pdf-text' : 'kpi-card-text',
      ]"
      data-tag="p"
      title=""
      :data-tooltip="tooltipText"
      :data-text="text"
    >
      {{ text }}
    </p>
    <p
      :class="[' truncate mb-0', pdf ? 'pdf-sub-text' : 'kpi-card-sub-text']"
      data-tag="p"
      title=""
      data-tooltip="true"
      :data-text="subText"
    >
      {{ subText }}
    </p>
  </div>
</template>
<script>
export default {
  name: "KpiCardV2",
  props: {
    card: {
      type: Boolean,
      default: true,
    },
    title: {
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    subText: {
      type: String,
      default: null,
    },
    brandName: {
      type: String,
      default: null,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
    showHover: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.artist-performance-card {
  border-radius: 8px;

  .card-body {
    .icon {
      height: 30px;
      width: 30px;
    }
  }
}

.hover-card:hover {
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5) !important;
  transition: all 0.3s ease !important;
}

.card-background {
  background-color: aliceblue !important;
}
</style>
