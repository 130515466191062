<template>
  <div
    class="song-detail-v2 mb-8 d-flex justify-content-start flex-row"
    id="song_detail_v2"
    v-loading.fullscreen.lock="loading"
  >
    <div class="song-main-content w-100" @scroll="handleScroll()">
      <div class="shadow-card card song-sub-navBar">
        <div class="row">
          <div class="col-12">
            <div class="top-menu-list me-2" id="song_menu_list" role="tablist">
              <ul class="list-inline mt-4">
                <li
                  class="list-inline-item ms-2"
                  @click="scrollToElementWithMargin('song_overview', 125)"
                >
                  <a
                    data-section-main="#song_overview"
                    id="song_overview_tab"
                    class="active"
                  >
                    <img
                      src="/media/svg/BR-Blue.svg"
                      alt="br-blue icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center"> Overview </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="
                    scrollToElementWithMargin('all_time_key_track_stats', 125)
                  "
                >
                  <a
                    data-section-main="#all_time_key_track_stats"
                    id="all_time_key_track_stats_tab"
                  >
                    <img
                      src="/media/svg/top10.svg"
                      alt="top10 icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center">
                      All Time Track Stats
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="
                    scrollToElementWithMargin('song_spotify_streams', 125)
                  "
                >
                  <a
                    data-section-main="#song_spotify_streams"
                    id="song_spotify_streams_tab"
                  >
                    <img
                      src="/media/svg/iconmonstr-spotify-1.svg"
                      alt="spotify icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center">
                      Spotify Streams
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="
                    scrollToElementWithMargin('song_soundcloud_plays', 125)
                  "
                >
                  <a
                    data-section-main="#song_soundcloud_plays"
                    id="song_soundcloud_plays_tab"
                  >
                    <img
                      src="/media/svg/social-logos/soundcloud.svg"
                      alt="soundcloud icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center">
                      Soundcloud Plays
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="scrollToElementWithMargin('song_youtube_views', 125)"
                >
                  <a
                    data-section-main="#song_youtube_views"
                    id="song_youtube_views_tab"
                  >
                    <img
                      src="/media/svg/social-logos/youtube.svg"
                      alt="youtube icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center">
                      Youtube Views
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="
                    scrollToElementWithMargin('song_tiktok_followers', 125)
                  "
                >
                  <a
                    data-section-main="#song_tiktok_followers"
                    id="song_tiktok_followers_tab"
                  >
                    <img
                      src="/media/svg/social-logos/tiktok.svg"
                      alt="tiktok icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center">
                      TikTok Followers
                    </span>
                  </a>
                </li>
                <li
                  class="list-inline-item ms-2"
                  @click="scrollToElementWithMargin('song_shazam_streams', 125)"
                >
                  <a
                    data-section-main="#song_shazam_streams"
                    id="song_shazam_streams_tab"
                  >
                    <img
                      src="/media/svg/social-logos/shazam.svg"
                      alt="shazam icon"
                      class="song-social-icon"
                    />
                    <span class="social-icon text-xs-center"> Shazam </span>
                  </a>
                </li>
              </ul>
              <hr />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-start">
            <div class="top-menu-list" id="song_sub_sub_menu_list">
              <ul class="list-inline">
                <li
                  class="list-inline-item ms-4 sub-list-inline"
                  v-for="(list, index) in subListArray"
                  :key="index"
                >
                  <span
                    :class="[
                      'list-text text-xs-center',
                      activeSubList === list.id ? 'active-sub-text' : '',
                    ]"
                    @click="scrollToElementWithMargin(list.id, 155)"
                    :data-section="'#' + list.id"
                  >
                    {{ list.tabName }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="search-bar">
              <input
                class="form-control form-control-solid me-3 search-field"
                placeholder="Search other songs"
              />
              <svg
                class="search-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-6" v-if="!loading">
        <div id="song_overview">
          <div class="artist-content-heading">Overview</div>
          <SongOverviewV2 :track="track" />
        </div>
        <div id="all_time_key_track_stats">
          <div class="artist-content-heading">All Time Track Stats</div>
          <AllTimeTrackStatsV2
            :stats="
              track &&
              track.chartmetricArtistTrackRelease &&
              track.chartmetricArtistTrackRelease.latest
                ? track.chartmetricArtistTrackRelease.latest
                : {}
            "
          />
        </div>
        <div v-for="(stream, index) in platformStreams" :key="index">
          <div :id="stream.main_id">
            <div class="artist-content-heading">{{ stream.mainHeading }}</div>
            <PlatformStreamsV2 :platform="stream" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, onMounted, ref } from "vue";
import SongOverviewV2 from "@/song/SongOverviewV2.vue";
import PlatformStreamsV2 from "@/song/PlatformStreamsV2.vue";
import AllTimeTrackStatsV2 from "@/song/AllTimeTrackStatsV2.vue";
import $ from "jquery";
import { scrollToElementWithMargin } from "@/utils/staticHelper";
import { useRoute } from "vue-router";
import { getTrackDetailsBySpotifyIdAndTrackId } from "@/api/mongoTracks";

export default {
  name: "SongDetailV2",
  components: {
    SongOverviewV2,
    AllTimeTrackStatsV2,
    PlatformStreamsV2,
  },
  setup() {
    const loading = ref(false);
    const activeTab = ref("song_overview");
    const activeSubList = ref("song_bio");
    const route = useRoute();
    const subListArray = ref([{ tabName: "Song Bio", id: "song_bio" }]);
    const platformStreams = ref([
      {
        platform: "spotify",
        main_id: "song_spotify_streams",
        sub_id: "song_streams",
        mainHeading: "Spotify Streams",
        cardTitle: "Streams",
        yAxisTitle: "Spotify Streams",
        colors: ["#48B955"],
      },
      {
        platform: "soundcloud",
        main_id: "song_soundcloud_plays",
        sub_id: "soundcloud_plays",
        mainHeading: "Soundcloud Plays",
        cardTitle: "Plays",
        yAxisTitle: "Soundcloud Plays",
        colors: ["#FB663B"],
      },
      {
        platform: "youtube",
        main_id: "song_youtube_views",
        sub_id: "song_views",
        mainHeading: "Youtube Views",
        cardTitle: "Views",
        yAxisTitle: "Youtube Views",
        colors: ["#FA423B"],
      },
      {
        platform: "tiktok",
        main_id: "song_tiktok_followers",
        sub_id: "tiktok_followers",
        mainHeading: "TikTok Followers",
        cardTitle: "Followers",
        yAxisTitle: "TikTok Followers",
        colors: ["#000000"],
      },
      {
        platform: "shazam",
        main_id: "song_shazam_streams",
        sub_id: "song_shazam",
        mainHeading: "Shazam",
        cardTitle: "Shazam",
        yAxisTitle: "Shazam",
        colors: ["#2979ff"],
      },
    ]);
    const track = ref({});
    onMounted(async () => {
      if (route.params.spotifyID && route.params.trackID) {
        await getTrackDetail(route.params.spotifyID, route.params.trackID);
      }
    });
    onBeforeMount(() => {
      window.addEventListener("scroll", handleScroll);
    });
    const getTrackDetail = async (spotifyID, trackID) => {
      try {
        loading.value = true;
        let { data } = await getTrackDetailsBySpotifyIdAndTrackId(
          spotifyID,
          trackID
        );
        track.value = data ? data : {};
        data = {};
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const handleScroll = () => {
      const scrollPos = $(document).scrollTop() + 250;
      $("#song_menu_list a").each(function () {
        const currLink = $(this);
        const refElement = $(currLink.data("section-main"));
        if (
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $("#song_menu_list li a").removeClass("active");
          changeTab(currLink.data("section-main"));
          currLink.addClass("active");
        } else {
          currLink.removeClass("active");
        }
      });
      if (scrollPos === 250 || scrollPos < 250) {
        $("#song_overview_tab").addClass("active");
        changeTab("#song_overview");
        activeSubList.value = "song_bio";
      }
      $("#song_sub_sub_menu_list li span").each(function () {
        const currList = $(this);
        const refList = $(currList.attr("data-section"));
        if (
          refList.position().top <= scrollPos &&
          refList.position().top + refList.height() > scrollPos
        ) {
          activeSubList.value = currList.attr("data-section").replace("#", "");
        }
      });
    };
    const changeTab = (tabName) => {
      activeTab.value = tabName;
      if (tabName === "#song_overview") {
        subListArray.value = [{ tabName: "Song Bio", id: "song_bio" }];
      } else if (tabName === "#all_time_key_track_stats") {
        subListArray.value = [
          { tabName: "Key Track Stats", id: "key_track_stats" },
          { tabName: "Monthly Track Stats", id: "monthly_track_stats" },
        ];
      } else if (tabName === "#song_spotify_streams") {
        subListArray.value = [{ tabName: "Streams", id: "song_streams" }];
      } else if (tabName === "#song_soundcloud_plays") {
        subListArray.value = [{ tabName: "Plays", id: "soundcloud_plays" }];
      } else if (tabName === "#song_youtube_views") {
        subListArray.value = [{ tabName: "Views", id: "song_views" }];
      } else if (tabName === "#song_tiktok_followers") {
        subListArray.value = [{ tabName: "Followers", id: "tiktok_followers" }];
      } else if (tabName === "#song_shazam_streams") {
        subListArray.value = [{ tabName: "Shazam", id: "song_shazam" }];
      } else {
        subListArray.value = [];
      }
    };
    return {
      loading,
      activeTab,
      activeSubList,
      subListArray,
      track,
      platformStreams,
      handleScroll,
      scrollToElementWithMargin,
    };
  },
};
</script>
<style lang="scss" scoped>
.song-sub-navBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 11;
  transition: 0.8s;
}

.sub-list-inline {
  padding: 2px !important;
}

.active-sub-text {
  color: black !important;
  font-weight: 600;
}

input::placeholder {
  color: #33647f !important;
  font-size: 12px;
  font-weight: 400;
}

.search-bar {
  position: relative;
  width: -webkit-fill-available;
  margin-right: 1rem;
  margin-top: -5px;
}

.search-field {
  padding: 0.5rem 1.5rem !important;
  line-height: 0 !important;
}

.search-icon {
  position: absolute;
  right: 2%;
  font-size: 24px;
  bottom: 30%;
  fill: #33647f;
}

.song-social-icon {
  height: 20px;
  width: 20px;
}
</style>
