<template>
  <div class="mt-2" :id="platform.sub_id">
    <StreamsChartV2
      :cardTitle="platform.cardTitle"
      :platform="platform.platform"
      :yAxisTitle="platform.yAxisTitle"
      :colors="platform.colors"
    />
  </div>
</template>
<script>
import StreamsChartV2 from "@/song/StreamsChartV2.vue";

export default {
  name: "PlatformStreamsV2",
  components: { StreamsChartV2 },
  props: {
    platform: {
      type: Object,
      required: true,
    },
  },
};
</script>
